* {
  font-family: "Inter", sans-serif;
  overscroll-behavior: none;
}

html {
  font-size: 17px;
  /*15px*/
  box-sizing: border-box;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming)*/
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */

  background-color: #F8F9FA;
}

@media (max-width: 740px) {
  html {
    font-size: 15px;
  }

  /* Links */
  .styles_modal__gNwvD {
    overflow-x: hidden;
  }
}


body,
sub_title,
overline,
caption {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 1.33;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.24px;
  color: #343A40;
  margin: 0;
  padding: 0;
  text-align: left;
}

html,
body,
#root {
  height: 100%;
}

p {
  margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.35px;
}

/* 36px */
h1 {
  font-size: 2.267rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.4px;
}

/* 28px */
h2 {
  font-size: 1.87rem;
  font-weight: 700;
  line-height: 1.2;
}

/* 22px */
h3 {
  font-size: 1.46rem;
}

/* 20px */
h4 {
  font-size: 1.33rem;
}

/* 17px */
h5 {
  font-size: 1.13rem;
  letter-spacing: 0.38px;
}

.bodyLarge {
  font-size: 1.06rem;
  letter-spacing: -0.41px;
}

/* 13px */
.small {
  font-size: 0.867rem;
  letter-spacing: -0.08px;
}

/* 11px */
.extraSmall {
  font-size: 0.733rem;
  letter-spacing: 0.07px;
}

.micro {
  font-size: 0.667rem;
  letter-spacing: 0px;
}

/* 10px */
.caption {
  font-size: 0.8rem;
  letter-spacing: 0px;
}

/* START: These styles are not in the standard stylesheet */
.businessCaption {
  font-size: 0.75rem;
  color: #343a40;
  font-weight: 600;
}

a {
  color: #262626;
  text-decoration: none;
}

.Toastify__toast {
  border-radius: 4px !important;
  font-size: 1rem !important;
}


.Toast_Domain_ClassName {
  background-color: #4C4C4C !important;
}

.Toast_Domain_BodyClassName {
  color: white !important;
}

.rhap_container {
  border-radius: 12px !important;
}

.react-phone-number-input__icon-image {
  display: block;
}

/* -- CodeMirror -- */
.cm-editor {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

}

.cm-editor-html>.cm-editor {
  background-color: #001226;
  height: 700px;
}

.cm-editor-txt-plain>.cm-editor {
  height: 300px;
  border-radius: 4px;
  border: 1px solid #C4C4C4;
  padding: 5px;
}

.cm-editor-txt-plain>.cm-editor>.cm-scroller>.cm-gutters {
  display: none
}

.cm-editor span {
  font-family: 'Space Mono', monospace;
}

.cm-editor-html>.ͼ3 .cm-gutters {
  background-color: #001226;
}

span.ͼ1c {
  color: #DD4167
}

/* -- Notifications Emails -- */

/* Select Fields */
div.selectFieldMessage {
  margin-right: 30px;
  color: red;
  min-height: 30px;
}

div.selectFieldProvider {
  padding: 0;
}


/* -- Chonky -- */

/* Top of the folder */
.folderBackSideMid-0-3-14 {
  background-color: #CED4DA !important;
}

/* Inside of a folder */
.folderBackSideMid-0-3-14>* {
  background-color: #CED4DA !important;
}

/* Title of files */
.chonky-fileEntryClickableWrapper>div>div+div>span {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px !important;
  vertical-align: middle;
  transition: all 2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Title attribute for hover of spans */
.chonky-fileEntryClickableWrapper>div>div+div>span[title]:hover::before {
  -webkit-transition: all 3s;
  transition: all 3s;
  content: attr(title);
  position: absolute;
  top: -45%;
  left: 50%;
  z-index: 50;
  background-color: rgba(0, 0, 0, .8);
  color: white;
  box-shadow: -1px 38px 80px -18px rgb(0 0 0 / 78%);
  white-space: pre-wrap;
  transform: translate(-50%, -50%);
}

/* FileList Date section */
.chonky-fileListWrapper>div:nth-child(2)>div>div>div>div>div>div:nth-child(5) {
  min-width: 27%;
}

/* File without title background */
.chonky-fileEntryClickableWrapper>div>div {
  background-color: transparent;
}

/* File icons */
div[data-test-id="file-entry"]>div>div>svg {
  display: none;
}

/* Selection background */
div[data-test-id="file-entry"]>div>div:nth-child(2) {
  background: repeating-linear-gradient(45deg, rgb(233 167 51 / 14%), rgb(233 167 51 / 14%) 10px, rgb(233 167 51 / 14%) 0px, rgb(233 167 51 / 14%) 20px) rgb(233 167 51 / 14%) !important;
}

/* File image/video */
.chonky-fileThumbnail {
  border: 1px solid rgb(255, 105, 0);
}

/* FileList Size section */
.chonky-fileListWrapper>div:nth-child(2)>div>div>div>div>div>div:nth-child(6) {
  max-width: 12%;
}

/* Center thumbail */
.chonky-fileEntryClickableWrapper>div>div>div>div+div {
  border-top: 2px solid white !important;
  margin-top: 8px;
}

/* Thumbnail */
.chonky-fileEntryClickableWrapper>div>div>div>* {
  background-color: #CED4DA !important;
}

/* Styles to align Items in the toolbar */
.chonky-toolbarWrapper,.chonky-toolbarContainer, .chonky-toolbarLeft{
  min-height: 42px;
  margin-bottom: 10px;
}

.chonky-toolbarRight, .chonky-infoText{
  min-height: 42px;
  display: flex;
  align-items: center;
}

/* SearchField */
.chonky-searchFieldInput {
  background-color: #F8F9FA;
  border-color: 1px solid #DEE2E6;
  width: 260px !important;
  height: 36px !important;
  font-family: "Inter", sans-serif !important;
  font-weight: 300 !important;
  font-size: 15px !important;
  line-height: 20px !important;
  
  min-height: 42px;
  caret-color: #fc500e;
  border-radius: 4px;
}

/* Styles to add custom border colors from SearchField  */
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(232, 91, 45, 0.41) !important;
  box-shadow: rgba(232, 91, 45, 0.25) 0px 0px 8px 0px !important;
}

/* Styles to add custom color to icons inside the SearchField */
.svg-inline--fa.fa-search , .svg-inline--fa.fa-circle-notch.fa-spin{
  color: #868E96 !important;
}

/* Extra info, beside SearchField */
.chonky-infoContainer {
  margin-left: 30px;
}

span.chonky-selectionSizeText {
  color: #ff6900 !important;
}

/* Extra info text ex: number of items, beside SearchField */
p.chonky-infoText {
  padding-left: 73px;
  font-family: "Inter", sans-serif !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  color: #3F4A55;
}

/* -- End of Chonky custom styles -- */

/* Complete Chonky Div */
.MuiBox-root {
  border: 0 !important;
  border-radius: 0px !important;
}

/* Spans of RightToolbar */
div.MuiBox-root span {
  font-family: "Inter", sans-serif !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  color: #3F4A55;
}
